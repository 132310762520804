new Swiper('.customers__swiper', {
  keyboard: {
    enabled: true,
    onlyInViewport: true,
  },
  loop: true,
  speed: 6000,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  spaceBetween: 20,
  freeMode: true,

  breakpoints: {
    320: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 4,
    },
    1200: {
      slidesPerView: 8,
    },
  },
});
