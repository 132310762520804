import { refs, hideElement, showElement } from '/src/common/js';

refs.btnChat.addEventListener('click', onBtnChatClick);

export function showBtnChat() {
  showElement(refs.btnChat.parentElement);
}
export function hideBtnChat() {
  hideElement(refs.btnChat.parentElement);
}

export function onBtnChatClick(e) {
  if (e.target.classList.contains('active')) hideChatList();
  else showChatList();
}

export function showChatList() {
  refs.btnChat.classList.add('active', 'btn-cross');
  showElement(refs.chatList);
}
export function hideChatList() {
  refs.btnChat.classList.remove('active', 'btn-cross');
  hideElement(refs.chatList);
}