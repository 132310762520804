import { hideElement, showElement } from '/src/common/js';

const mobileMenuRefs = {
  menu: document.querySelector('.mobile-menu'),
  btnOpen: document.querySelector('.mobile-menu__btn'),
};

mobileMenuRefs.btnOpen.addEventListener('click', toggleBurgerMenu);
mobileMenuRefs.menu.addEventListener('click', toggleBurgerMenu);

function toggleBurgerMenu() {
  const expanded = mobileMenuRefs.btnOpen.getAttribute('aria-expanded') === 'true' || false;

  if (expanded) hideElement(mobileMenuRefs.menu);
  else showElement(mobileMenuRefs.menu);

  mobileMenuRefs.btnOpen.setAttribute('aria-expanded', !expanded);
  document.body.classList.toggle('mobileMenuIsOpen');
}
