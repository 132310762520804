import { hideChatList } from "../btn-chat/btn-chat";
import { refs, hideElement, showElement } from "/src/common/js";

refs.btnUp.addEventListener('click', onBtnUpClick);

export function showBtnUp() {
  showElement(refs.btnUp);
}
export function hideBtnUp() {
  hideElement(refs.btnUp)
}

function onBtnUpClick() {
  window.scrollTo(0, 0);
  hideChatList();
}