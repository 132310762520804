import {
  hideElement,
  refs,
  setElClassCurrent,
  showElement,
} from '../../../common/js';

refs.servicesTabNavList.addEventListener('click', onServicesNavListClick);
refs.servicesTabContentList.addEventListener('click',onServicesContentListClick);

function onServicesNavListClick(e) {
  if (!e.target.classList.contains('tabs-nav__btn')) return;

  setElClassCurrent(e, '[data-servicenav]');
  setServices(e);
}

function setServices(e) {
  refs.servicesTabElements.forEach(i => {
    if (i.dataset.service === e.target.dataset.servicenav) showElement(i);
    else hideElement(i);
  });
}



function onServicesContentListClick(e) {
  if (!e.target.classList.contains('service-desc__btn')) return;

  const serviceDescContainer = e.target?.closest('.service-desc');
  const serviceDescExtra = serviceDescContainer?.querySelector(
    '.service-desc__extra-content'
  );

  // serviceDescExtra.classList.contains('isHidden') ? showElement(serviceDescExtra) : hideElement(serviceDescExtra);
  if (serviceDescExtra.classList.contains('isHidden')) {
    showElement(serviceDescExtra);
    e.target.innerText = 'Сховати'
  } else {
    hideElement(serviceDescExtra);
    e.target.innerText = 'Показати більше'
  }
}
