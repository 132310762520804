import { hideLoader, showLoader } from "../loader/loader";
import { showElement, hideElement, refs } from "/src/common/js";

const modalForm = document.forms.modalForm;
const contactForm = document.forms.contactForm;

modalForm.addEventListener('submit', onFormSubmit);
contactForm.addEventListener('submit', onFormSubmit);

function onFormSubmit(e) {
  e.preventDefault();
  
  sendMail(e.target);
  e.target.reset();
}

async function sendMail(formRef) {
  const formData = new FormData(formRef);
  let sendURL = '/sendmail.php';
  const msgSuccess = document.querySelector('.modal__msg--success');
  const msgError = document.querySelector('.modal__msg--error ');
  
  showLoader();
  try {
    let response = await fetch(sendURL, {
      method: "POST",
      body: formData,
    });
    if (response.ok) {
      showElement(msgSuccess);
    }
    else showElement(msgError);
  } catch (exceptionVar) {
    showElement(msgError);
  } finally {
    hideLoader();
    const hideMsg = setTimeout(() => {
      hideElement(refs.modalForm);
      hideElement(msgError);
      hideElement(msgSuccess);
      clearTimeout(hideMsg);
    }, 5000);
  }

  // if (response) showElement(msgSuccess);
  // if (!response) showElement(msgError);

  



  // let response = await fetch(sendURL, {
  //   method: "POST",
  //   body: formData,
  // });

  // if (response.ok) {
  //   let result = await response.json();
  //   console.log(result);
  //   alert(result);
  // } else {
  //   console.log('ERROR');
  //   alert('ERROR');
  // }






  // if (response.ok) {
  //   let result = await response.json();
  //   console.log(result);
  //   alert(result);
  // } else {
  //   console.log('ERROR');
  //   alert('ERROR');
  // }



  // .then(res => {
  //   console.log(res);
  //   // showResponse(JSON.parse(res));
  // })
  // .catch(err => {
  //   console.log(err)
  //   // showResponse();
  // });
}

// function showResponse(response) {
//   const msgSuccess = document.querySelector('.modal__msg--success');
//   const msgError = document.querySelector('.modal__msg--error ');

//   if (response) showElement(msgSuccess);
//   if (!response) showElement(msgError);

//   const hideMsg = setTimeout(() => {
//     hideElement(refs.modalForm);
//     hideElement(msgError);
//     hideElement(msgSuccess);
//     clearTimeout(hideMsg);
//   }, 5000);
// }