import refs from "./refs";
import { hideBtnChat, showBtnChat } from "/src/components/btns/btn-chat/btn-chat";
import { hideBtnUp, showBtnUp } from "/src/components/btns/btn-up/btn-up";

window.addEventListener('scroll', onWindowScroll);

function onWindowScroll() {
  const windowPageYOffset = window.pageYOffset;

  if (windowPageYOffset > 100) {
    refs.body.classList.add('isScrolling');

    if (windowPageYOffset > window.innerHeight - 60) refs.header.classList.add('white');
    else if (windowPageYOffset <= window.innerHeight - 60) refs.header.classList.remove('white');

    if (windowPageYOffset > 500) {
      showBtnUp();
      showBtnChat();
    }
    else if (windowPageYOffset <= 500) {
      hideBtnUp();
      hideBtnChat();
    }
  } else if (windowPageYOffset <= 100) refs.body.classList.remove('isScrolling');
}
